@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-initiatives-board {
    .dialog-title {
      @include mat.typography-level($typography, "Aeroport/xl/medium");
    }

    .dialog-text {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }
    .drop-title,
    .info-message {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }

  .initiative-detail-root {
    .solution-info-container {
      h4 {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
      }

      span {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/xxl/Regular"
        );
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $error: map.get($config, "error");

  app-initiatives-board {
    background-color: mat.get-color-from-palette($greyscale, 850);

    .initiative-container {
      background-color: mat.get-color-from-palette(
        $background,
        "bg-container-widget"
      );
      @include nista.box-border(
        mat.get-color-from-palette($greyscale, 850),
        "1px"
      );
    }

    .drop-title,
    .info-message {
      color: mat.get-color-from-palette($greyscale, 200);
    }

    .initiative-box {
      background-color: mat.get-color-from-palette(
        $background,
        "bg-container-3"
      );
      @include nista.box-border(
        mat.get-color-from-palette($greyscale, 850),
        "1px"
      );
    }

    .drag-placeholder {
      display: block;
      background-color: mat.get-color-from-palette($uiprimary, 100);
      min-height: 2px;
      margin: 8px;
    }
  }

  .initiative-detail-root {
    .solution-info-container {
      background-color: mat.get-color-from-palette($greyscale, 850);

      div {
        h4 {
          color: mat.get-color-from-palette($greyscale, 300);
        }

        span {
          color: mat.get-color-from-palette($greyscale, 150);
        }
      }
    }
  }

  .cdk-drag-preview .initiative-box {
    box-sizing: border-box;
    border-radius: 4px;
    background-color: mat.get-color-from-palette($greyscale, 850);
    @include nista.box-border(
      mat.get-color-from-palette($uiprimary, 100),
      "2px"
    );
  }

  .list-wrapper {
    &:has(.cdk-drop-list-dragging) {
      .initiative-container {
        &:has(.cdk-drop-list-dragging),
        &:has(.cdk-drop-list-receiving) {
          @include nista.box-border(
            mat.get-color-from-palette($uiprimary, 100),
            "2px"
          );
          .info-message {
            display: none;
          }
        }
        .info-message {
          display: initial;
          p {
            margin-top: 16px;
          }
        }
        @include nista.box-border(
          mat.get-color-from-palette($error, 500),
          "2px"
        );
      }
    }

    .info-message {
      display: none;
    }
  }
}
