@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $secondary: map.get($config, "secondary");
  $greyscale: map.get($config, "greyscale");
  .hexcontainer {
    .hexbase {
      &.yellow {
        background-color: mat.get-color-from-palette($secondary, "power", 0.5);
      }
      &.purple {
        background-color: mat.get-color-from-palette(
          $secondary,
          "mass-flow-rate",
          0.5
        );
      }
      &.cyan {
        background-color: mat.get-color-from-palette(
          $secondary,
          "currency",
          0.5
        );
      }
      &.blue {
        background-color: mat.get-color-from-palette($secondary, "time", 0.5);
      }
      &.green {
        background-color: mat.get-color-from-palette($secondary, "volume", 0.5);
      }
      &.red {
        background-color: mat.get-color-from-palette(
          $secondary,
          "pressure",
          0.5
        );
      }
    }
    .hexprogress {
      &.yellow {
        background-color: mat.get-color-from-palette($secondary, "power");
      }
      &.purple {
        background-color: mat.get-color-from-palette(
          $secondary,
          "mass-flow-rate"
        );
      }
      &.cyan {
        background-color: mat.get-color-from-palette($secondary, "currency");
      }
      &.blue {
        background-color: mat.get-color-from-palette($secondary, "time");
      }
      &.green {
        background-color: mat.get-color-from-palette($secondary, "volume");
      }
      &.red {
        background-color: mat.get-color-from-palette($secondary, "pressure");
      }
    }
    path {
      &.yellow {
        fill: #120f01cc;
      }
      &.purple {
        fill: #25034fcc;
      }
      &.cyan {
        fill: #112321cc;
      }
      &.blue {
        fill: #07101bcc;
      }
      &.green {
        fill: #001309cc;
      }
      &.red {
        fill: #200613cc;
      }
    }

    &.locked {
      .hexbase {
        background-color: mat.get-color-from-palette($greyscale, 850);
      }
      path {
        fill: mat.get-color-from-palette($greyscale, 700);
      }
    }
  }
}
