/* You can add global styles to this file, and also import other style files */
@import "gnista-styles.scss";

html {
  overflow: hidden;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  height: 100vh;
}

app-root {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  display: block;
  height: 100%;
  width: 100%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #ddd;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  opacity: 0.35;
}

.loading-center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  :first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

a[app-dashboard-sidebar-button] {
  text-decoration: none !important;
  &:hover,
  &:visited {
    text-decoration: none !important;
  }
}

h1,
h2,
h3 {
}

:focus {
  outline: unset;
}
