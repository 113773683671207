@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .timepicker-dropdown {
    &.timepicker-lg {
      span {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
    }
    &.timepicker-md {
      span {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
    &.timepicker-sm {
      span {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");

  .timepicker-wrapper,
  .timepicker-dropdown {
    span:hover {
      background-color: mat.get-color-from-palette($greyscale, 800);
    }
    span.selected {
      background-color: mat.get-color-from-palette($greyscale, 700);
    }
  }

  .timepicker-dropdown {
    background-color: mat.get-color-from-palette($greyscale, 850);

    @include nista.box-border(
      mat.get-color-from-palette($greyscale, 800),
      "1px"
    );
    ng-scrollbar:first-of-type {
      @include nista.box-border-right(
        mat.get-color-from-palette($greyscale, 800),
        "1px"
      );
    }
    span {
      color: mat.get-color-from-palette($greyscale, 100);
    }
  }
}
