@use "@angular/material"as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .tag-root {
    @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
  }
  .option{
    @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $warn: map.get($config, "warn");
  $error: map.get($config, "error");
  $transparency: map.get($config, "transparency");

  .dropdown-tag {
    .item {
      color: mat.get-color-from-palette($greyscale, A100);

      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
      &.active {
        background-color: mat.get-color-from-palette($transparency, 20);
      }
      &.disabled {
        color: mat.get-color-from-palette($greyscale, 400);
      }
    }

    &.opened {
      background-color: mat.get-color-from-palette($greyscale, 850);
      box-shadow: 0 0 0 1px mat.get-color-from-palette($greyscale, 600),
        0px 12px 12px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .tag {
    &.info-blue {
      background-color: mat.get-color-from-palette($info, 150);
      color: mat.get-color-from-palette($info, 850);
      &:hover
      {
        background-color: mat.get-color-from-palette($info, 300);
        color: mat.get-color-from-palette($info, 900);
        .toggle-icon {
          @include nista.icon-color(mat.get-color-from-palette($info, 900));
        }
      }
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($info, 850));
      }
    }

    &.primary-blue {
      background-color: mat.get-color-from-palette($uiprimary, 150);
      color: mat.get-color-from-palette($uiprimary, 850);
      &:hover
      {
        background-color: mat.get-color-from-palette($uiprimary, 300);
        color: mat.get-color-from-palette($uiprimary, 900);
        .toggle-icon {
          @include nista.icon-color(mat.get-color-from-palette($uiprimary, 900));
        }
      }
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($uiprimary, 850));
      }
    }

    &.electric-blue {
      background-color: mat.get-color-from-palette($uiprimary, 700);
      color: mat.get-color-from-palette($uiprimary, 50);
      &:hover
      {
        background-color: mat.get-color-from-palette($uiprimary, 800);
        color: mat.get-color-from-palette($uiprimary, 150);
        .toggle-icon {
          @include nista.icon-color(mat.get-color-from-palette($uiprimary, 150));
        }
      }
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($uiprimary, 50));
      }
    }

    &.green {
      background-color: mat.get-color-from-palette($success, 150);
      color: mat.get-color-from-palette($success, 850);
      &:hover
      {
        background-color: mat.get-color-from-palette($success, 300);
        color: mat.get-color-from-palette($success, 900);
        .toggle-icon {
          @include nista.icon-color(mat.get-color-from-palette($success, 900));
        }
      }
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($success, 900));
      }
    }

    &.red {
      background-color: mat.get-color-from-palette($error, 150);
      color: mat.get-color-from-palette($error, 850);
      &:hover
      {
        background-color: mat.get-color-from-palette($error, 300);
        color: mat.get-color-from-palette($error, 900);
        .toggle-icon {
          @include nista.icon-color(mat.get-color-from-palette($error, 900));
        }
      }
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($error, 850));
      }
    }

    &.orange {
      background-color: mat.get-color-from-palette($warn, 150);
      color: mat.get-color-from-palette($warn, 850);
      &:hover
      {
        background-color: mat.get-color-from-palette($warn, 300);
        color: mat.get-color-from-palette($warn, 900);
        .toggle-icon {
          @include nista.icon-color(mat.get-color-from-palette($warn, 900));
        }
      }
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($warn, 850));
      }
    }

    &.purple {
      background-color: #e5d1ff;
      color: #532889;
      &:hover
      {
        background-color: #D0ACFF;
        color: #461485;
        .toggle-icon {
          @include nista.icon-color(#461485);
        }
      }
      .toggle-icon {
        @include nista.icon-color(#532889);
      }
    }

    &.grey {
      background-color: mat.get-color-from-palette($greyscale, 150);
      color: mat.get-color-from-palette($greyscale, 850);
      &:hover
      {
        background-color: mat.get-color-from-palette($greyscale, 300);
        color: mat.get-color-from-palette($greyscale, 900);
        .toggle-icon {
          @include nista.icon-color(mat.get-color-from-palette($greyscale, 900));
        }
      }
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 850));
      }
    }
    &.outline {
      background-color: transparent;
      box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 300);
      color: mat.get-color-from-palette($greyscale, 300);
      &:hover
      {
        background-color: mat.get-color-from-palette($transparency, 20);
      }
    }
  }
}
