@use "@angular/material"as mat;
@use "sass:map";
@use '../nista-mixins' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .navigation-root {
    .sub-nav-button {
      @include mat.typography-level($typography, "Aeroport/sm/medium");
    }
  }

  .navigation-button-root {
    .button-text {
      @include mat.typography-level($typography, "Aeroport/sm/medium");
    }
  }
  .workspace-chooser {
    .title {
      @include mat.typography-level($typography, "Desktop/Oxygen/xs/Regular");
    }
    .text {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $primary: map.get($config, primary);

  #sidebar {
    background-color: mat.get-color-from-palette($greyscale, 1000);
    @include nista.box-border-right(
      mat.get-color-from-palette($greyscale, 800)
    );
  }
  .navigation-button-root {
    color: mat.get-color-from-palette($greyscale, 300);
    @include nista.icon-color(mat.get-color-from-palette($greyscale, 300));
    background-color: mat.get-color-from-palette($greyscale, 1000);
    @include nista.box-border-right(
      mat.get-color-from-palette($greyscale, 800)
    );

    &:hover {
      color: mat.get-color-from-palette($greyscale, 150);
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 150));
      background-color: mat.get-color-from-palette($greyscale, 850);
      @include nista.box-border-right(
        mat.get-color-from-palette($greyscale, 800)
      );
    }
    &.is-active {
      color: mat.get-color-from-palette($greyscale, A100);
      background-color: mat.get-color-from-palette($greyscale, 900);
      @include nista.box-border-right(
        mat.get-color-from-palette($primary, 500)
      );
      &:hover {
        color: mat.get-color-from-palette($greyscale, A100);
        background-color: mat.get-color-from-palette($greyscale, 850);
      }
    }
  }

  .navigation-root {
    background-color: mat.get-color-from-palette($greyscale, 1000);
    @include nista.box-border-bottom(
      mat.get-color-from-palette($greyscale, 800)
    );

    .work-together {
      app-user-badge {
        filter: drop-shadow(-4px 0px 4px rgba(0, 0, 0, 0.25));
      }
    }

    .seperator {
      background-color: mat.get-color-from-palette($greyscale, 800);
    }
    .sub-nav-button {
      height: 52px;

      color: mat.get-color-from-palette($greyscale, 300);
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 800)
      );

      &.is-active {
        color: mat.get-color-from-palette($greyscale, A100);
        background-color: mat.get-color-from-palette($greyscale, 900);

        box-shadow: -1px -3px 0px -1px mat.get-color-from-palette($primary, 500)
          inset;
      }
      &:hover {
        color: mat.get-color-from-palette($greyscale, 150);
        background-color: mat.get-color-from-palette($greyscale, 850);
        text-decoration: none !important;
      }
    }
    .sidepart {
      .comments-icon button {
        color: mat.get-color-from-palette($greyscale, 300);
        background-color: mat.get-color-from-palette($greyscale, 1000);
        @include nista.box-border-bottom(
          mat.get-color-from-palette($greyscale, 800)
        );
        &.is-active {
          color: mat.get-color-from-palette($greyscale, A100);
          background-color: mat.get-color-from-palette($greyscale, 900);

          box-shadow: -1px -3px 0px -1px
            mat.get-color-from-palette($primary, 500) inset;
        }
        &:hover {
          color: mat.get-color-from-palette($greyscale, 300);
          background-color: mat.get-color-from-palette($greyscale, 850);
          text-decoration: none !important;
        }
      }
    }
  }
}
