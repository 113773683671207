@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $greyscale: map.get($config, "greyscale");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $transparency: map.get($config, "transparency");

  .group-visualisations-root {
    .section {
      background-color: mat.get-color-from-palette($background, bg-container-2);
      border: 1px solid mat.get-color-from-palette($greyscale, 800);
      border-radius: 2px;
    }

    .overlay-inner {
      background-color: rgba(#131313, 0.75);
    }

    .visualisation-info-labels {
      h4 {
        color: mat.get-color-from-palette($greyscale, 200);
      }
      span {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }

    .group-visualisations-display-root {
      .visualisation-content {
        border-top: 1px solid mat.get-color-from-palette($greyscale, 800);
      }
      .title-row {
        h3 {
          color: mat.get-color-from-palette($greyscale, 150);
        }
      }
    }
  }
  .container {
    .profile-body{
      label{
        color: mat.get-color-from-palette($greyscale, 200);
      }
      .dates{
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }
  }
}

@mixin typography($typography) {
  .group-visualisations-root {
    .visualisation-info-labels {
      h4 {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
      }
      span {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
    }
  }

  .group-visualisations-display-root {
    .title-row {
      h3 {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/lg/Regular"
        );
      }
    }
  }

  .container{
    .profile-body{
      label{
        @include mat.typography-level(
          $typography,
          "Desktop/Oxygen/xl/Regular"
        );
      }
      .dates{
        @include mat.typography-level(
          $typography,
          "Desktop/Oxygen/md/Regular"
        );
      }
    }
  }
}
