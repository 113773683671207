@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .update-info-badge-root {
    p,
    span {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");

  .update-info-badge-root {
    p,
    span {
      color: mat.get-color-from-palette($greyscale, 300);
    }
  }
}
