@use "@angular/material" as mat;
@use "sass:map";
@use "/src/nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .report-dashboard-savings-root {
    .title {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }

    .chartText {
      .percent {
        font-family: Aeroport;
        font-size: 40px;
        line-height: 40px;
      }
      .openPercent {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }

    .yearlyGoalReminder {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }

    .savings-numbers {
      > div {
        .headline {
          @include mat.typography-level(
            $typography,
            "Desktop/Oxygen/md/Regular"
          );
        }

        .numbers {
          @include mat.typography-level(
            $typography,
            "Desktop/Aeroport/xxl/Regular"
          );
        }
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $transparent: map.get($config, "transparency");

  .report-dashboard-savings-root {
    .yearlyGoalReminder {
      color: mat.get-color-from-palette($greyscale, 200);
    }

    .savings-numbers {
      @include nista.box-border-left(#4c4c4c);

      .numbers {
        color: mat.get-color-from-palette($greyscale, 200);
        &.empty {
          color: mat.get-color-from-palette($greyscale, 400);
        }
      }

      .estimated {
        .dot {
          background-color: #cef091;
        }
      }
      .covered {
        .dot {
          background-color: #cef09180;
        }
      }
      .total {
        .dot {
          background-color: #4c4c4c;
        }
      }
    }
  }
}
