@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .dropdown.opened,
  .item {
    &.select-tag {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
    }
    &.select-small {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
    }
    &.select-medium {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }
    &.select-large {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }

  .select-root {
    &.select-tag {
      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
      }
      .selectedViewValue {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
      }
    }
    &.select-small {
      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
      }
      .selectedViewValue {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
      }
    }
    &.select-medium {
      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
      .selectedViewValue {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
    &.select-large {
      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
      .selectedViewValue {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $primary: map.get($config, "primary");

  .dropdown {
    .item:first-child{
      border-radius: 4px 4px 0 0;
    }
    .item:last-child{
      border-radius: 0 0 4px 4px;
    }
    .item {
      color: mat.get-color-from-palette($greyscale, A100);

      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
      &.active {
        background-color: mat.get-color-from-palette($uiprimary, 600);
      }
      &.disabled {
        color: mat.get-color-from-palette($greyscale, 400);
      }
    }
    &.info-blue {
      .item {
        background-color: mat.get-color-from-palette($info, 150);
        color: mat.get-color-from-palette($info, 850);

        &:hover {
          background-color: mat.get-color-from-palette($uiprimary, 300);
        }
        &.active {
          background-color: mat.get-color-from-palette($uiprimary, 200);
        }
      }
    }

    &.primary-blue {
      .item {
        background-color: mat.get-color-from-palette($uiprimary, 150);
        color: mat.get-color-from-palette($uiprimary, 700);

        border-bottom: 1px solid mat.get-color-from-palette($uiprimary, 700);
        &:hover {
          background-color: mat.get-color-from-palette($uiprimary, 200);
        }
        &.active {
          background-color: mat.get-color-from-palette($uiprimary, 300);
        }
      }
    }

    &.green {
      .item {
        background-color: mat.get-color-from-palette($success, 150);
        color: mat.get-color-from-palette($info, 850);

        &:hover {
          background-color: mat.get-color-from-palette($success, 200);
        }
        &.active {
          background-color: mat.get-color-from-palette($success, 300);
        }
      }
    }

    &.purple {
      .item {
        background-color: #e5d1ff;
        color: #532889;

        border-bottom: 1px solid #532889;
        &:hover {
          background-color: #a994c4;
        }
        &.active {
          background-color: rgb(142, 115, 175);
        }
      }
    }

    &.grey {
      .item {
        background-color: mat.get-color-from-palette($greyscale, 150);
        color: mat.get-color-from-palette($info, 850);

        &:hover {
          background-color: mat.get-color-from-palette($greyscale, 300);
        }
        &.active {
          background-color: mat.get-color-from-palette($greyscale, 200);
        }
      }
    }

    &.outline {
      .item {
        background-color: transparent;
        box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 400);
        color: mat.get-color-from-palette($greyscale, 400);

        border-bottom: 1px solid mat.get-color-from-palette($greyscale, 300);
        &:hover {
          background-color: mat.get-color-from-palette($greyscale, 300);
        }
        &.active {
          background-color: mat.get-color-from-palette($greyscale, 200);
        }
      }
    }

    .dropdown {
      background-color: rgba(255, 255, 255, 0.04);
      color: mat.get-color-from-palette($greyscale, A100);
      z-index: 1001;
    }
    &.opened {
      background-color: mat.get-color-from-palette($greyscale, 850);
      box-shadow: 0 0 0 1px mat.get-color-from-palette($greyscale, 600),
        0px 12px 12px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .select-root {
    .label {
      color: mat.get-color-from-palette($greyscale, 100);
    }

    .closed {
      border: 1px solid mat.get-color-from-palette($greyscale, 500);
      background-color: rgba(255, 255, 255, 0.04);
      color: mat.get-color-from-palette($greyscale, 300);
      .selectIcon {
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 150));
      }
      &.openState {
        border: 1px solid mat.get-color-from-palette($greyscale, 150);
        background-color: rgba(255, 255, 255, 0.04);
        color: mat.get-color-from-palette($greyscale, 50);
      }
      &:hover {
        border: 1px solid mat.get-color-from-palette($greyscale, 150);
      }
    }

    &.info-blue {
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($uiprimary, 850));
      }
      .selectedViewValue {
        &.active {
          color: mat.get-color-from-palette($uiprimary, 850);
        }
      }
      .closed {
        background-color: mat.get-color-from-palette($info, 150);
        border: 1px solid mat.get-color-from-palette($info, 150);
        &:hover {
          border: 1px solid mat.get-color-from-palette($info, 400);
        }
      }
    }

    &.primary-blue {
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($uiprimary, 700));
      }
      .selectedViewValue {
        &.active {
          color: mat.get-color-from-palette($uiprimary, 700);
        }
      }
      .closed {
        background-color: mat.get-color-from-palette($uiprimary, 150);
        border: 1px solid mat.get-color-from-palette($uiprimary, 150);
        &:hover {
          border: 1px solid mat.get-color-from-palette($uiprimary, 400);
        }
      }
    }

    &.green {
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($success, 800));
      }
      .selectedViewValue {
        &.active {
          color: mat.get-color-from-palette($success, 800);
        }
      }
      .closed {
        background-color: mat.get-color-from-palette($success, 150);
        border: 1px solid mat.get-color-from-palette($success, 150);
        &:hover {
          border: 1px solid mat.get-color-from-palette($success, 400);
        }
      }
    }

    &.purple {
      .toggle-icon {
        @include nista.icon-color(#532889);
      }
      .selectedViewValue {
        &.active {
          color: #532889;
        }
      }
      .closed {
        background-color: #e5d1ff;
        border: 1px solid #e5d1ff;
        &:hover {
          border: 1px solid #532889;
        }
      }
    }

    &.grey {
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 850));
      }
      .selectedViewValue {
        &.active {
          color: mat.get-color-from-palette($greyscale, 850);
        }
      }
      .closed {
        background-color: mat.get-color-from-palette($greyscale, 150);
        border: 1px solid mat.get-color-from-palette($greyscale, 150);
        &:hover {
          border: 1px solid mat.get-color-from-palette($greyscale, 400);
        }
      }
    }

    &.outline {
      .toggle-icon {
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 400));
      }
      .selectedViewValue {
        &.active {
          color: mat.get-color-from-palette($greyscale, 400);
        }
      }
      .closed {
        border: 1px solid mat.get-color-from-palette($greyscale, 400);
        &:hover {
          border: 1px solid mat.get-color-from-palette($greyscale, 150);
        }
      }
    }

    color: mat.get-color-from-palette($greyscale, A100);
    .toggle-icon {
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 300));
    }
    .up {
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));
    }
    .selectedViewValue {
      color: mat.get-color-from-palette($greyscale, 300);
      &.active {
        color: mat.get-color-from-palette($greyscale, 50);
      }
    }

    &.disabled {
      .closed {
        border: 1px solid mat.get-color-from-palette($greyscale, 800);
        cursor: default;
        background-color: mat.get-color-from-palette($greyscale, 800);
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 400));
        .selectedViewValue {
          color: mat.get-color-from-palette($greyscale, 400);
        }
      }
    }
  }
}
