@use '@angular/material'as mat;
@use 'sass:map';

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $foreground: map.get($config, foreground);
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");

  .cdk-drag-preview {
    background: mat.get-color-from-palette($background);
  }

  .box {
    color: mat.get-color-from-palette($foreground);
  }

  .droplist-container {
    &.root-container {
      padding: 50px;

      &.empty {
        border: 0.5px solid mat.get-color-from-palette($greyscale, 300);
      }
    }

    &.drop-inside {
      border: 2px solid mat.get-color-from-palette($accent, 500) !important;
      padding: 0px;
    }

    &.empty {
    }
  }

  #dragindicator {
    > .drag-line {
      height: 100%;
      border-left: 2px solid #5acdff !important;
      position: relative;
    }

    height: 60px;
    width: 0px;
    overflow: visible;
  }

  .drop-inside {
    > .node-blox {
      border: 2px solid mat.get-color-from-palette($accent, 500) !important;
      padding: 0px;
    }
  }

  .drop-fillPlaceholder {
    > .node-item {
      border: 2px solid mat.get-color-from-palette($accent, 500) !important;
      padding: 0px;
    }
  }

  .drop-delete {
    border: 3px solid mat.get-color-from-palette($accent, 500) !important;
  }

  .deleteicon {
    border-color: mat.get-color-from-palette($greyscale, 300);
    font-size: x-large;
  }

  .operator-item,
  .node-item,
  .node-blox {
    > :first-child,
    .layoutpart {
      background-color: mat.get-color-from-palette($greyscale, 800);
    }

    padding: 2px;
    fill: mat.get-color-from-palette($foreground, "text");

    .name,
    .collapsedName {
      color: mat.get-color-from-palette($foreground, "text");
    }

    .unit {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }

    &.selected {
      border: 2px solid;
      padding: 0px;
    }

    margin-left: 15px;
    margin-right: 15px;
  }

  .node-blox {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-background"
    );
    border: 1px solid mat.get-color-from-palette($greyscale, 700);
    padding: 1px;

    > :first-child {
      background-color: transparent;
    }

    .block-function {
      color: mat.get-color-from-palette($greyscale, 300);
      > .inline-icon {
        fill: mat.get-color-from-palette($greyscale, 300);
      }
    }
  }

  .sidebar {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    );
  }

  .right-container-calculation {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    );

    .section,
    .sideBarSection {
      border-bottom-color: mat.get-color-from-palette(
        $background,
        "background"
      );
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
  }

  .left-container-calculation {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    );
  }

  .datapoint-block {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    );
    height: 26px;

    .datapoint {
      border: 1px solid transparent;

      &:hover {
        border: 1px solid mat.get-color-from-palette($primary);
      }
    }
  }

  *.as-split-gutter {
    background-color: mat.get-color-from-palette(
      $background,
      "background"
    ) !important;
  }

  .result-area {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    ) !important;
  }

  .toolbar-container {
    background-color: black;
  }

  .strokebutton {
    font-size: 12px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-family: "Oxygen" !important;
    border-color: mat.get-color-from-palette($greyscale, 300) !important;
    fill: mat.get-color-from-palette($greyscale, 300) !important;

    &:hover {
      border-color: white !important;
    }
  }

  .userSeparator {
    border-color: mat.get-color-from-palette($greyscale, 800);
  }

  .overlay-wrapper {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    ) !important;
    border-color: mat.get-color-from-palette($greyscale, 800);
  }

  .equals {
    background-color: mat.get-color-from-palette($accent);
  }

  .nameinput-row > app-inline-input {
    border-color: mat.get-color-from-palette($greyscale, 700);
  }

  .calc-container .toolbar > .save-icon {
    fill: mat.get-color-from-palette($greyscale, 300);
  }
}
