@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-datapoint-chooser {
  }
  app-datapoint-chooser-popup {
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $error: map.get($config, "error");

  app-datapoint-chooser {
    .chooser {
      @include nista.box-border(#fff, "1px");
    }
  }

  app-datapoint-chooser-popup {
    --popup-border: #ffffff;

    .datapoint-chooser-popup-root {
      background-color: mat.get-color-from-palette($greyscale, 850);
      border-bottom: 1px solid var(--popup-border);
    }

    .popup {
      border-left: 1px solid var(--popup-border);
      border-right: 1px solid var(--popup-border);
    }

    .datapoint-row {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 500),
        "1px"
      );

      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 700);
      }

      &.selected {
        background-color: mat.get-color-from-palette($uiprimary, 600);
      }
    }
  }
}
