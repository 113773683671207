@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .input-wrapper-minimal {
    &.input-xlarge {
      .editbox {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/xxl/Regular"
        );
      }
    }
    &.input-large {
      .editbox {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/lg/Regular"
        );
      }
    }
    &.input-medium {
      .editbox {
        @include mat.typography-level(
          $typography,
          "Desktop/Oxygen/xl/Regular"
        );
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $error: map.get($config, "error");

  .input-wrapper-minimal {
    .editbox {
      color: mat.get-color-from-palette($greyscale, 150);
      &::placeholder {
        color: mat.get-color-from-palette($greyscale, 300) !important;
      }
      &[readonly="true"]::placeholder {
        color: mat.get-color-from-palette($greyscale, 150) !important;
      }
    }

    background-color: rgba(255, 255, 255, 0);
    * {
      background-color: rgba(255, 255, 255, 0);
    }

    &.editAllowed {
      &:hover {
        background-color: rgba(255, 255, 255, 0.16);
      }
      &:focus-within {
        color: mat.get-color-from-palette($greyscale, 150);
        background-color: rgba(255, 255, 255, 0.16);
      }

      &.error {
        @include nista.box-border(mat.get-color-from-palette($error, 500));
      }
    }
  }
}
