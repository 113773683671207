@use "@angular/material" as mat;
@use "sass:map";
@use "../nista-mixins" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .snackbar-root {
    .headline {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }

    .text {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }

    .action {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $foreground: map.get($config, "foreground");
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");

  .snackbar-root {
    color: mat.get-color-from-palette($greyscale, A100);
    background-color: mat.get-color-from-palette($greyscale, 800);
    box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 700);

    .closeicon {
      &:hover * {
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 300));
      }
    }

    .action:hover {
      color: mat.get-color-from-palette($greyscale, 300);
    }

    &.snackbar-noaction,
    &.snackbar-oneaction {
      .closeicon {
        box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 700);
      }
    }

    &.snackbar-twoaction {
      &.snackbar-non-closeable {
        .action-button-1 {
          box-shadow: 1px 0px 0px 0px inset
            mat.get-color-from-palette($greyscale, 700);
        }
        .action-button-2 {
          box-shadow: 0 0 0 1px inset
            mat.get-color-from-palette($greyscale, 700);
        }
      }
    }
  }

  .toast-container {
    padding-top: 16px;

    .ngx-toastr {
      border-radius: 0px;
      background-position: inherit;
      box-shadow: none;
      background-size: inherit;
      padding: 0px;
    }
    &.toast-top-center,
    &.toast-bottom-center {
      width: 100% !important;
      .ngx-toastr {
        width: fit-content;
      }
    }
  }
}

@import "~ngx-toastr/toastr.css";
