@use '@angular/material' as mat;
@use 'sass:map';
@use '../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);
  @if $color != null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-facility-detail {
    .profile-body {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $greyscale: map.get($config, "greyscale");

  app-facility-detail {
    .facility-detail-body {
      background-color: mat.get-color-from-palette($greyscale, 1000);
    }

    .profile-header {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 850)
      );
    }
  }
}
