@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-nista-expander {
    .title-row span {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $accent: map.get($config, "accent");
  $primary: map.get($config, "primary");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $transparency: map.get($config, "transparency");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");

  app-nista-expander {
    .expander-root {
      @include nista.box-border(mat.get-color-from-palette($greyscale, 600));

      background-color: mat.get-color-from-palette($greyscale, 900);

      .title-row {
        @include nista.box-border-bottom(
          mat.get-color-from-palette($greyscale, 600)
        );

        span {
          color: mat.get-color-from-palette($greyscale, 200);
          margin-right: auto;
        }

        &:hover {
          background-color: mat.get-color-from-palette($transparency, 20);

          span {
            color: mat.get-color-from-palette($greyscale, 100);
          }
        }

        &:focus:not(:focus-visible) {
          @include nista.box-border(
            mat.get-color-from-palette($transparency, 5)
          );
          @include nista.box-border-bottom(
            mat.get-color-from-palette($greyscale, 600)
          );
        }

        &:focus-visible {
          @include nista.box-border(mat.get-color-from-palette($greyscale, 50));
          border: 1px mat.get-color-from-palette($greyscale, 50);
          border-radius: 2px 2px 0 0;
          background-color: mat.get-color-from-palette($transparency, 20);
          span {
            color: mat.get-color-from-palette($greyscale, 100);
          }
        }
      }
    }
  }
}
