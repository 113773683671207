@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $greyscale: map.get($config, "greyscale");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $transparency: map.get($config, "transparency");

  .userlist-root {
    .header {
      background-color: mat.get-color-from-palette($greyscale, 800);
      color: mat.get-color-from-palette($greyscale, 300);
    }
  }

  .userlistrow-root {
    .userrow {
      color: mat.get-color-from-palette($greyscale, 200);

      .second {
        color: mat.get-color-from-palette($greyscale, 300);
      }

      &.inviteOnly {
        .namerow {
          color: mat.get-color-from-palette($greyscale, 300);
        }
      }

      &.disabled {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }
  }

  app-userlist-row {
    .userrow {
      border-bottom: 1px solid mat.get-color-from-palette($greyscale, 800);
    }
    &:last-of-type {
      .userrow {
        border-bottom: 0px;
      }
    }
  }
}

@mixin typography($typography) {
  .userlist-root {
    .header {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }
  }

  .userlistrow-root {
    @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    .second {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
    }
  }
}
