@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .solution-thread-root {
    .message {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }

    .initial-message,
    .reply-message {
      .user-name {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
      }
      .createdAt {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
      }
    }

    .reply-list {
      &.collapsed {
        .reply-count,
        .last-reply-name {
          @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
        }
        .last-reply {
          @include mat.typography-level(
            $typography,
            "Desktop/Oxygen/sm/Regular"
          );
        }
      }
      &.expanded {
        .header {
          @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
        }
      }
    }

    .reply-field {
      .header {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
    a.reply-link {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
    }

    .thread-actions {
      app-nista-checkbox {
        .label {
          @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
        }
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $accent: map.get($config, "accent");
  $primary: map.get($config, "primary");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $transparency: map.get($config, "transparency");
  $background: map.get($config, "background");

  .comment.active {
    .solution-thread-root {
      @include nista.box-border(mat.get-color-from-palette($primary, 500));
    }
  }

  .solution-thread-root {
    background-color: mat.get-color-from-palette($background, bg-container-3);
    @include nista.box-border(mat.get-color-from-palette($transparency, 40));

    // opacity: 0.65;
    // &.acceptedSolution {
    //   opacity: unset;
    // }
    &.rejectedSolution {
      opacity: 0.65;
    }

    .message {
      color: mat.get-color-from-palette($greyscale, 100);
      .editedFlag {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }

    .thread-actions {
      display: flex;
      gap: 8px;
      border-top: 1px solid mat.get-color-from-palette($transparency, 40);
      &.bottom-border {
        border-bottom: 1px solid mat.get-color-from-palette($transparency, 40);
      }
      .spacer {
        margin: 0px;
        background: mat.get-color-from-palette($transparency, 40);
        width: 1px;
      }

      app-nista-checkbox {
        .select.default > .label {
          color: mat.get-color-from-palette($greyscale, 300);
        }
      }
    }

    .messageEditor {
      background-color: mat.get-color-from-palette($greyscale, 850);
    }

    .initial-message {
      .user-name {
        color: mat.get-color-from-palette($greyscale, 200);
      }
      .createdAt {
        color: mat.get-color-from-palette($greyscale, 200);
      }
    }

    .reply-message,
    .initial-message {
      .user-name {
        color: mat.get-color-from-palette($greyscale, 100);
      }
      .createdAt {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }

    .reply-list {
      &.collapsed {
        color: mat.get-color-from-palette($greyscale, 200);
        &:hover {
          background-color: mat.get-color-from-palette($greyscale, 850);
        }
      }
      &.expanded {
        > .header {
          color: mat.get-color-from-palette($greyscale, 200);
          &:hover {
            background-color: mat.get-color-from-palette($greyscale, 850);
          }
        }
      }
    }

    .reply-field {
      .header {
        color: mat.get-color-from-palette($greyscale, 200);
      }
    }

    a.reply-link {
      cursor: pointer;
      text-decoration: none;
      color: mat.get-color-from-palette($accent, 200) !important;
      @include nista.icon-color(mat.get-color-from-palette($accent, 200));
      &:hover {
        color: mat.get-color-from-palette($accent, 300) !important;
        @include nista.icon-color(mat.get-color-from-palette($accent, 300));
        text-decoration: none !important;
      }
    }

    .comment-state {
      &.isOpen {
        background-color: mat.get-color-from-palette(
          $greyscale,
          700
        ) !important;
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 200));
        &:hover {
          background-color: mat.get-color-from-palette(
            $success,
            600
          ) !important;
          @include nista.icon-color(mat.get-color-from-palette($success, 50));
        }
      }
      &.resolved {
        background-color: mat.get-color-from-palette($success, 600) !important;
        @include nista.icon-color(mat.get-color-from-palette($success, 50));
      }
    }
  }
}
