@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $greyscale: map.get($config, "greyscale");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $transparency: map.get($config, "transparency");

  .edit-user-dialog-container {
    .username{
      color: mat.get-color-from-palette($greyscale, 150);
    }
    .email{
      color: mat.get-color-from-palette($greyscale, 300);
    }
  }
}
  
@mixin typography($typography) {
  .edit-user-dialog-container {
    .username{
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
    .email{
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
    }
  }

