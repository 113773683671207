@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-datapoint-inline-input {
    .label {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  $transparent: map.get($config, "transparency");

  app-datapoint-inline-input {
    .input-container {
      border-radius: 2px;
      border: 1px solid mat.get-color-from-palette($transparent, 40);
      background-color: mat.get-color-from-palette($greyscale, 1000);
    }

    .label {
      color: mat.get-color-from-palette($greyscale, 200);
    }
  }
}
