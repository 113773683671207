@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .input-wrapper {
    &.input-large {
      .editbox,
      input.editbox::placeholder {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }

      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
    &.input-medium {
      .editbox,
      input.editbox::placeholder {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }

      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
    &.input-small {
      .editbox,
      input.editbox::placeholder {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
      }

      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");

  .input-wrapper {
    .label {
      color: mat.get-color-from-palette($greyscale, 100);
    }

    .editbox-border {
      .editbox {
        color: mat.get-color-from-palette($greyscale, 50);
        &::placeholder {
          color: mat.get-color-from-palette($greyscale, 300) !important;
        }
      }

      @include nista.icon-color(mat.get-color-from-palette($greyscale, 300));
      @include nista.box-border(mat.get-color-from-palette($greyscale, 500));

      background-color: rgba(255, 255, 255, 0.04);
      * {
        background-color: rgba(255, 255, 255, 0);
      }

      &:hover {
        @include nista.box-border(
          mat.get-color-from-palette($greyscale, 50),
          "1px"
        );
      }
      &:focus-within {
        @include nista.box-border(
          mat.get-color-from-palette($greyscale, A100),
          "1px"
        );
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 150));
      }

      &.error {
        @include nista.box-border(mat.get-color-from-palette($error, 500));
        &:hover {
          @include nista.box-border(mat.get-color-from-palette($error, 500));
        }
        &:focus-within {
          @include nista.box-border(mat.get-color-from-palette($error, 500));
        }
      }
      &.valid {
        @include nista.box-border(mat.get-color-from-palette($success, 500));
        &:hover {
          @include nista.box-border(mat.get-color-from-palette($success, 500));
        }
        &:focus-within {
          @include nista.box-border(mat.get-color-from-palette($success, 500));
        }
      }
      &.disabled {
        background-color: mat.get-color-from-palette($greyscale, 800);
        .editbox {
          color: mat.get-color-from-palette($greyscale, 400);
        }

        @include nista.icon-color(mat.get-color-from-palette($greyscale, 400));
        @include nista.box-border(
          mat.get-color-from-palette($greyscale, 400),
          "0px"
        );
        &:hover {
          @include nista.box-border(
            mat.get-color-from-palette($greyscale, 400),
            "0px"
          );
        }
        &:focus-within {
          @include nista.box-border(
            mat.get-color-from-palette($greyscale, 400),
            "0px"
          );
        }
      }
    }
  }
}
