@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  @if $color != null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $greyscale: map.get($config, "greyscale");

  .dot {
    background-color: mat.get-color-from-palette($primary, 500);
    color: mat.get-color-from-palette($greyscale, A100);
  }
}
