.app-loading {
  .logo {
    width: 100px;
    height: 100px;

    // this way asset gets processed by webpack
    background: url(./assets/nista_logo.svg) center center no-repeat;
    background-size: contain;
  }
}
