@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
    $config: mat.get-color-config($theme);
    @if $config != null {
        @include color($theme);
    }
}

@mixin color($theme) {
    // Get the color config from the theme.
    $config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary: map.get($config, "primary");
    $background: map.get($config, "background");
    $greyscale: map.get($config, "greyscale");

    .rating.color {
        &-ok,
        &-negative,
        &-positive,
        &-default {
            .star-container .star {
                svg {
                    fill: mat.get-color-from-palette($primary) !important;
                }
                i {
                    color: mat.get-color-from-palette($primary) !important;
                }
            }
        }
    }

    .feedback-button {
        color: mat.get-color-from-palette($greyscale, 300);
        fill: mat.get-color-from-palette($greyscale, 300);
        &:hover {
            color: mat.get-color-from-palette($greyscale, A100);
            fill: mat.get-color-from-palette($greyscale, A100);
        }
    }

    .screenshot {
        > .imagecontainer {
            img {
                border: 1px solid mat.get-color-from-palette($greyscale, A100);
            }
        }
        .icon {
            color: mat.get-color-from-palette($primary);
            text-shadow: none;
        }
    }
    @import "~css-star-rating/css/star-rating.min.css";
}
