@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .notification-dropdown {
    h1 {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }
    .notification-item {
      .title {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
      .createdAt,
      .workspaceName {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $accent: map.get($config, "accent");
  $primary: map.get($config, "primary");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $transparency: map.get($config, "transparency");
  $background: map.get($config, "background");
    
  .notification-root {
    .unread {
      background-color: mat.get-color-from-palette($primary, 400);
    }
    &.active {
      background-color: mat.get-color-from-palette($greyscale, 850);
      button {
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));
      }
    }
  }
  .notification-dropdown {
    background-color: mat.get-color-from-palette($background, "bg-container-popupmenu");
    border: 1px solid mat.get-color-from-palette($greyscale, 600);
    border-radius: 4px;

    h1 {
      color: mat.get-color-from-palette($greyscale, 100);
    }

    .top {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 600)
      );

      .menu.active {
        background-color: mat.get-color-from-palette(
          $transparency,
          50
        ) !important;

        @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));
      }

      .navigation {
        button {
          &:hover {
            background-color: mat.get-color-from-palette(
              $transparency,
              5
            ) !important;
          }
        }

        button.is-active {
          box-shadow: -1px -3px 0px -1px
            mat.get-color-from-palette($primary, 500) inset;
          color: mat.get-color-from-palette($greyscale, 100) !important;
        }
      }
    }
    .notification-item {
      cursor: pointer;
      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
      &:not(:last-child) { 
        .notification-wrapper { 
          @include nista.box-border-bottom(
            mat.get-color-from-palette($greyscale, 700)
          );
        }}


      .title {
        color: mat.get-color-from-palette($greyscale, 50);
      }
      .createdAt,
      .workspaceName {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }

    .unreadDot {
      div {
        background-color: mat.get-color-from-palette($accent, 400);
      }
    }

    .main-icon {
      background-color: mat.get-color-from-palette($greyscale, 700);
    }
  }
}
