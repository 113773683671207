@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  app-nista-comparison-plus-linechart {
    .improved {
      .icon-container {
        background-color: rgba(
          mat.get-color-from-palette($success, 300),
          0.3
        );
        border-radius: 100%;
        .trend {
          i {
            background-color: mat.get-color-from-palette($success, 300);
          }
        }
      }
      .colored {
        color: mat.get-color-from-palette($success, 300);
      }
    }
    .worsened {
      .icon-container {
        background-color: #ff48584d;

        border-radius: 100%;
        .trend {
          i {
            background-color: #ff4858;
            transform: rotate(180deg);
          }
        }
      }
      .colored {
        color: #ff4858 !important;
      }
    }
  }
}
