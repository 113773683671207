@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-visualisation-enpi-chooser {
    .content {
      .progress-text {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Mono");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");

  app-visualisation-enpi-chooser {
    .content {
      .empty-list {
        color: mat.get-color-from-palette($greyscale, 500);
      }

      .linked-datapoints {
        border-bottom: 1px solid mat.get-color-from-palette($greyscale, 400);
      }
    }

    .tile {
      .image:hover {
        @include nista.box-border(
          mat.get-color-from-palette($greyscale, "A100"),
          "1px"
        );
      }
    }

    .stage2 {
      .info-heading {
        color: mat.get-color-from-palette($greyscale, 100);
      }
      color: mat.get-color-from-palette($greyscale, "A100");
    }
  }
}
