@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin expandedStyle($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $primary: map.get($config, primary);

  @include nista.box-border-bottom(mat.get-color-from-palette($greyscale, 800));
}

@mixin color($config-or-theme) {
  .wrapper {
    .sidebar-workspace-switcher,
    .sidebar-facility-switcher {
      @media not all and (max-width: 768px) {
        &:not(.collapsed) {
          @include expandedStyle($config-or-theme);
        }
      }
    }
  }
}
