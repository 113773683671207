@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .facility-switcher-menu-root {
    .facility-search {
      h2 {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
      span {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
  }

  .facility-chooser {
    .title {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
    }
    .name {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }

  .menu-entry {
    span {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $primary: map.get($config, primary);

  .facility-chooser {
    .title {
      color: mat.get-color-from-palette($greyscale, 400);
    }
    .name {
      color: mat.get-color-from-palette($greyscale, 150);
    }

    .facility-icon {
      background-color: #02c6d1;
    }

    .menu-chevron {
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 400));
    }

    &:hover,
    &.active {
      background-color: mat.get-color-from-palette($greyscale, 850);
      text-decoration: none !important;

      // Box-shadow bottom and right
      $border-color-test: mat.get-color-from-palette($greyscale, 800);
      box-shadow: -1px -2px 0px -1px $border-color-test inset,
        -1px 0px 0px 0px $border-color-test inset;
      -webkit-box-shadow: -1px -2px 0px -1px $border-color-test inset,
        -1px 0px 0px 0px $border-color-test inset;
      -moz-box-shadow: -1px -2px 0px -1px $border-color-test inset,
        -1px 0px 0px 0px $border-color-test inset;
    }
  }

  .facility-switcher-menu-root {
    background-color: mat.get-color-from-palette($greyscale, 850);
    box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.25);

    border-radius: 4px;
    border: 1px solid mat.get-color-from-palette($greyscale, 800);

    .facility-search {
      h2 {
        color: mat.get-color-from-palette($greyscale, 150);
      }
      span {
        color: mat.get-color-from-palette($greyscale, 300);
      }
      &.multiFacility {
        @include nista.box-border-bottom(
          mat.get-color-from-palette($greyscale, 800)
        );
      }
    }

    .menu-entry {
      color: mat.get-color-from-palette($greyscale, 100);
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }

      &.selected {
        background-color: rgba(40, 95, 245, 0.5);
        color: mat.get-color-from-palette($greyscale, A100);
      }
    }

    .facility-icon {
      background-color: #02c6d1;
    }

    .create-facility,
    .facility-overview {
      @include nista.box-border-top(
        mat.get-color-from-palette($greyscale, 800)
      );
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));

      .facility-overview-link,
      .create-facility-link {
        color: mat.get-color-from-palette($greyscale, 100) !important;

        &:hover {
          background-color: unset;
        }
      }
    }
  }
}
