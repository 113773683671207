@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .nista-button {
    &.button- {
      &small {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
      }
      &medium {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
      }
      &large {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
      }
    }
  }
  input[type="file"]::file-selector-button {
    @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $foreground: map.get($config, "foreground");
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");
  $transparency: map.get($config, "transparency");
  $accent: map.get($config, accent);
  $error: map.get($config, error);

  a.nista-button,
  input[type="file"]::file-selector-button {
    text-decoration: none !important;
    width: fit-content;
    &:hover {
      text-decoration: none !important;
    }
  }

  .nista-button {
    color: white;
    fill: white;
    outline: none;
    border: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.button- {
      &small {
        padding: 8px 12px 8px 12px;
        &.icon-only {
          padding: 8px;
        }
      }

      &medium {
        padding: 8px 16px 8px 16px;
        &.icon-only {
          padding: 8px;
        }
      }

      &large {
        padding: 12px 32px 12px 32px;
        &.icon-only {
          padding: 14px;
        }
      }

      &disabled {
        cursor: default;
        background-color: mat.get-color-from-palette(
          $transparency,
          50
        ) !important;
        &.button-ghost {
          background-color: mat.get-color-from-palette(
            $transparency,
            5
          ) !important;
        }
        &.button-link {
          background-color: mat.get-color-from-palette(
            $transparency,
            5
          ) !important;
        }
        color: mat.get-color-from-palette($transparency, 50) !important;
        @include nista.icon-color(
          mat.get-color-from-palette($transparency, 50)
        );
      }

      &primary {
        background-color: mat.get-color-from-palette($accent, 500);
        &:hover {
          background-color: mat.get-color-from-palette($accent, 600);
        }
        &:focus {
          box-shadow: inset 0 0 0 2px mat.get-color-from-palette($greyscale, 50);
        }
        &:focus:not(:focus-visible) {
          box-shadow: none;
        }
      }

      &secondary {
        color: mat.get-color-from-palette($greyscale, 100);
        box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 400);
        background-color: transparent;
        &:hover {
          box-shadow: 0 0 0 1px inset
            mat.get-color-from-palette($greyscale, 200) !important;
          background-color: transparent;
        }
        &:active {
          box-shadow: 0 0 0 1px inset
            mat.get-color-from-palette($greyscale, 200) !important;
        }
        &:focus {
          box-shadow: inset 0 0 0 2px mat.get-color-from-palette($greyscale, 50);
        }

        &:focus:not(:focus-visible) {
          box-shadow: 0 0 0 1px inset
            mat.get-color-from-palette($greyscale, 400);
        }
      }

      &tertiary {
        background-color: mat.get-color-from-palette($transparency, 40);
        color: mat.get-color-from-palette($greyscale, 100) !important;
        &:hover {
          background-color: mat.get-color-from-palette($transparency, 20);
        }
        &:active {
          background-color: mat.get-color-from-palette($transparency, 20);
          box-shadow: none !important;
        }
        &:focus {
          box-shadow: inset 0 0 0 2px mat.get-color-from-palette($greyscale, 50);
        }
        &:focus:not(:focus-visible) {
          box-shadow: none;
        }
      }

      &ghost {
        background-color: mat.get-color-from-palette($transparency, 5);
        color: mat.get-color-from-palette($greyscale, 300) !important;
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 300));
        &:hover {
          background-color: mat.get-color-from-palette($transparency, 50);
          color: mat.get-color-from-palette($greyscale, 100) !important;
          @include nista.icon-color(
            mat.get-color-from-palette($greyscale, 100)
          );
        }
        &:active {
          background-color: mat.get-color-from-palette($transparency, 30);
          color: mat.get-color-from-palette($greyscale, 100) !important;
          @include nista.icon-color(
            mat.get-color-from-palette($greyscale, 100)
          );
        }
        &:focus {
          box-shadow: inset 0 0 0 2px mat.get-color-from-palette($greyscale, 50);
        }
        &:focus:not(:focus-visible) {
          box-shadow: none;
        }
      }

      &danger {
        background-color: mat.get-color-from-palette($error, 500);
        &:hover {
          background-color: mat.get-color-from-palette($error, 600);
        }
        &:active {
          background-color: mat.get-color-from-palette($error, 600);
        }
        &:focus {
          box-shadow: inset 0 0 0 2px mat.get-color-from-palette($greyscale, 50);
        }
        &:focus:not(:focus-visible) {
          box-shadow: none;
        }
      }

      &link {
        padding: 8px 0px 8px 0px;
        color: mat.get-color-from-palette($greyscale, 300);
        box-shadow: 0;
        background-color: transparent;
        @include nista.icon-color(mat.get-color-from-palette($greyscale, 300));
        &:hover {
          color: mat.get-color-from-palette($greyscale, 150);
          @include nista.icon-color(
            mat.get-color-from-palette($greyscale, 150)
          );
        }
        &:focus {
          color: mat.get-color-from-palette($greyscale, 100);
          box-shadow: inset 0 0 0 2px
            mat.get-color-from-palette($greyscale, A100);
          @include nista.icon-color(
            mat.get-color-from-palette($greyscale, 100)
          );
        }

        &:focus:not(:focus-visible) {
          box-shadow: None;
        }
      }
    }
  }

  input[type="file"]::file-selector-button {
    color: white;
    fill: white;
    outline: none;
    border: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    color: mat.get-color-from-palette($greyscale, 100);
    box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 400);
    background-color: transparent;
    &:hover {
      box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 200) !important;
      background-color: transparent;
    }
    &:active {
      box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 200) !important;
    }
    &:focus {
      box-shadow: inset 0 0 0 2px mat.get-color-from-palette($greyscale, 50);
    }
  }
}
