@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .task-entry-root {
    .table-group-label {
      span {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
  }
  .no-tasks {
    span {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  $accent: map.get($config, "accent");
  $secondary: map.get($config, "secondary");

  .task-entry-root {
    background-color: mat.get-color-from-palette($greyscale, 850);

    .task-time {
      color: mat.get-color-from-palette($greyscale, 400);
    }
    .task-title {
      color: mat.get-color-from-palette($greyscale, 150);
    }
    .task-topic {
      color: mat.get-color-from-palette($greyscale, 200);
      &:hover.Grundlast {
        background-color: mat.get-color-from-palette($secondary, "power");
        color: mat.get-color-from-palette($greyscale, 950);
      }
    }

    .task-content.bordered {
      border-bottom: 1px solid mat.get-color-from-palette($greyscale, 800);
    }

    &.open {
      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 900);
      }
    }

    &.overdue {
      background-color: mat.get-color-from-palette($error, 900);
      &:hover {
        background-color: mat.get-color-from-palette($error, 950);
      }
      .task-time {
        color: mat.get-color-from-palette($error, 400);
      }
    }
    &.done {
      background-color: transparent;
      .task-title {
        color: mat.get-color-from-palette($greyscale, 400);
      }
      .task-topic {
        color: mat.get-color-from-palette($greyscale, 600);
      }
    }
    &.disabled {
      .task-title {
        color: mat.get-color-from-palette($greyscale, 500);
      }
    }
  }

  .task-control-root {
    @include nista.box-border-bottom(
      mat.get-color-from-palette($greyscale, 700),
      "1px"
    );
  }

  .task-table-root {
    .no-tasks {
      span {
        color: mat.get-color-from-palette($greyscale, 500);
      }
    }
    .table-group-label {
      background-color: mat.get-color-from-palette($greyscale, 950);
      h1 {
        color: mat.get-color-from-palette($greyscale, 200);
      }
      span {
        color: mat.get-color-from-palette($greyscale, 400);
      }
      &.isPast {
        h1 {
          color: mat.get-color-from-palette($greyscale, 400);
        }
      }
      &.today {
        h1 {
          color: mat.get-color-from-palette($accent, 400);
        }
        .table-group-label-day {
          background-color: mat.get-color-from-palette($accent, 500);
          h1 {
            color: mat.get-color-from-palette($greyscale, 100);
          }
        }
      }
    }
  }
}
