@use "@angular/material"as mat;
@use "sass:map";
@use '../../../../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .content-card {
    p {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }

    a {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      text-decoration: underline !important;
    }
    .help-content {
      a {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $error: map.get($config, "error");

  .content-card {
    background-color: mat.get-color-from-palette($greyscale, 1000);
    @include nista.box-border(
      mat.get-color-from-palette($greyscale, 800),
      "1px"
    );

    a {
      color: mat.get-color-from-palette($greyscale, 100) !important;
    }

    a:hover {
      color: mat.get-color-from-palette($greyscale, 50) !important;
    }

    p {
      color: mat.get-color-from-palette($greyscale, 300);
    }

    h1 {
      color: mat.get-color-from-palette($greyscale, 100);
    }

    .header {
      span {
        color: mat.get-color-from-palette($greyscale, 100);
      }
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 850)
      );
    }
  }

  .meterpoint-validation-error {
    color: mat.get-color-from-palette($error, 500);
  }
}
