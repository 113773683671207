@use "@angular/material" as mat;
@use "sass:map";
@use "../../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .meta-info-section {
    h4,
    .meta-info-value {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }
    meta-info {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $foreground: map.get($config, foreground);
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");
  $transparency: map.get($config, "transparency");

  .series-datapoint-display-root {
    .section {
      .section-body {
        border-bottom-color: mat.get-color-from-palette(
          $background,
          "dark-bg-lighter-10"
        );
        border-top-color: mat.get-color-from-palette(
          $background,
          "dark-bg-lighter-10"
        );
      }
      .settings-body {
        border-bottom-color: mat.get-color-from-palette(
          $background,
          "dark-bg-lighter-10"
        );
        border-top-color: mat.get-color-from-palette(
          $background,
          "dark-bg-lighter-10"
        );
      }
    }

    .section {
      background-color: mat.get-color-from-palette($background, bg-container-2);
      border: 1px solid mat.get-color-from-palette($greyscale, 800);
      border-radius: 2px;
    }

    .section-separation-top {
      @include nista.box-border-top(
        mat.get-color-from-palette($greyscale, 800)
      );
    }

    .section-separation-bottom {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 800)
      );
    }

    .section-separation-right {
      @include nista.box-border-right(
        mat.get-color-from-palette($greyscale, 800)
      );
    }

    .section-separation-bottom:last-of-type {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($transparency, 5)
      );
    }

    .section-header {
      p {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }

    .dateAndChart {
      background-color: mat.get-color-from-palette($background);
      border: 1px solid mat.get-color-from-palette($greyscale, 800);
    }

    .darkChart {
      background-color: mat.get-color-from-palette($greyscale, 1000);
      border: 1px solid mat.get-color-from-palette($greyscale, 800);
    }

    .expander-root {
      .darkChart {
        border-top: none;
      }
    }

    .startDate {
      font-size: 16px;
      color: mat.get-color-from-palette($greyscale, 150);
    }
  }

  .meta-info-section {
    .meta-info-title {
      color: mat.get-color-from-palette($greyscale, 200);
    }
  }
}
