@use "@angular/material" as mat;
@use "sass:map";

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $foreground: map.get($config, "foreground");
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");

  // Define any styles affected by the theme.
  .semi-highlight {
    color: mat.get-color-from-palette($greyscale, 300);

    * svg {
      fill: mat.get-color-from-palette($greyscale, 300) !important;
    }
  }

  .row.userrow {
    &.header {
      font-weight: bold;
      border-bottom: 1px solid mat.get-color-from-palette($greyscale, 800);
    }

    &:hover {
      &:not(.header) {
        background-color: mat.get-color-from-palette(
          $background,
          "dark-bg-lighter-5"
        );
        cursor: pointer;
      }
    }

    &.active {
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-5"
      );
    }
  }

  .section {
    .section-body {
      border-bottom-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-10"
      );
      border-top-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-10"
      );
    }
    .settings-body {
      border-bottom-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-10"
      );
      border-top-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-10"
      );
    }
  }
}
