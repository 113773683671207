@use "@angular/material" as mat;
@use "sass:map";
@use "/src/nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-enpi-list {
    .desc-area {
      p {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $error: map.get($config, "error");
  $success: map.get($config, "success");

  app-enpi-list {
    .desc-area {
      p {
        color: mat.get-color-from-palette($greyscale, 200);
      }
    }

    .lastweek-cell {
      app-gnista-icon {
        &.improved {
          background-color: rgba(
            mat.get-color-from-palette($success, 300),
            0.3
          );
          border-radius: 100%;
        }
        &.worsened {
          background-color: #ff48584d;
          border-radius: 100%;
        }
      }
    }
  }
}
