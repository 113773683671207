@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-tab-navigation-group {
    app-tab-navigation-button {
      @include mat.typography-level($typography, "Aeroport/sm/medium");
      .unreadIndicator {
        span {
          @include mat.typography-level($typography, "Desktop/Oxygen/xs/Bold");
        }
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  $accent: map.get($config, "accent");
  $transparency: map.get($config, "transparency");
  app-tab-navigation-group {
    .scrollIndicator,
    .scrollview-2 {
      @include nista.box-border-bottom(mat.get-color-from-palette($transparency, 30), "1px");
    }
    app-tab-navigation-button {
      button {
        background-color:  mat.get-color-from-palette($transparency, 5);
        color: mat.get-color-from-palette($greyscale, 300);
        &:focus {
          color: mat.get-color-from-palette($greyscale, 150);
          background-color: mat.get-color-from-palette($transparency, 20);
          @include nista.box-border(
            mat.get-color-from-palette($greyscale, A1000),
            "2px"
          );
        }
        &:hover,
        &:active {
          color: mat.get-color-from-palette($greyscale, 150);
          background-color: mat.get-color-from-palette($transparency, 20);
          box-shadow: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
        }
        &.active {
          color: mat.get-color-from-palette($greyscale, 150);
          background-color: mat.get-color-from-palette($transparency, 5);
          @include nista.box-border-bottom(
            mat.get-color-from-palette($primary, 500),
            "2px"
          );
        }
        .unreadIndicator {
          background-color: mat.get-color-from-palette($accent, 500);
          span {
            color: mat.get-color-from-palette($greyscale, A100);
          }
        }
      }
    }
    .placeholder-container {
      color: mat.get-color-from-palette($greyscale, 300);
    }
  }
}
