@use "@angular/material" as mat;
@use "sass:map";
@use "../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $transparency: map.get($config, "transparency");

  .nista-icon-button {
    background-color: mat.get-color-from-palette($transparency, 5) !important;
    @include nista.icon-color(mat.get-color-from-palette($greyscale, 300));
    border-radius: 4px;
    outline: none;
    border: none;
    align-items: center;
    display: inline-flex;
    flex-flow: row;
    cursor: pointer;

    &:hover {
      background-color: mat.get-color-from-palette(
        $transparency,
        50
      ) !important;
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));
    }

    &:active,
    &:focus:active {
      background-color: mat.get-color-from-palette(
        $transparency,
        50
      ) !important;
      box-shadow: none;
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));
    }

    &:focus {
      background-color: mat.get-color-from-palette(
        $transparency,
        40
      ) !important;
      box-shadow: 0 0 0 2px inset mat.get-color-from-palette($greyscale, A100);
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));
    }

    &.button- {
      &disabled {
        cursor: default;
        background-color: mat.get-color-from-palette(
          $transparency,
          50
        ) !important;
        color: mat.get-color-from-palette($transparency, 50) !important;
        @include nista.icon-color(
          mat.get-color-from-palette($transparency, 50)
        );
      }

      &small {
        padding: 2px;
      }

      &medium {
        padding: 4px;
      }

      &large {
        padding: 4px;
      }
    }
  }
}
