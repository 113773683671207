@use "@angular/material"as mat;
@use "sass:map";
@use '../../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");

  .create-workspace-popup-root {
    .heading {
      color: mat.get-color-from-palette($greyscale, 50);
    }

    .button-group {
      color: mat.get-color-from-palette($greyscale, 150);
    }
  }
}
