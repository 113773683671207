@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .nista-checkbox-root {
    .select .label {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  $info: map.get($config, "info");
  $warn: map.get($config, "warn");
  $accent: map.get($config, accent);

  .nista-checkbox-root {
    .select {
      &.default {
        &.selected {
          > .icon {
            background-color: mat.get-color-from-palette($accent, 500);
          }
          &.disabled {
            > .icon {
              background-color: mat.get-color-from-palette($greyscale, 700);
            }
          }
        }
        &.deselected {
          background-color: transparent;
          > .icon {
            @include nista.box-border(
              mat.get-color-from-palette($greyscale, 300),
              "2px"
            );
            &:hover {
              @include nista.box-border(
                mat.get-color-from-palette($greyscale, 50),
                "2px"
              );
            }
          }
          &.disabled {
            > .icon {
              @include nista.box-border(
                mat.get-color-from-palette($greyscale, 600),
                "2px"
              );
              &:hover {
                @include nista.box-border(
                  mat.get-color-from-palette($greyscale, 600),
                  "2px"
                );
              }
            }
          }
        }
        > .label {
          color: mat.get-color-from-palette($greyscale, 100);
        }
      }
      &.toggle {
        &.selected {
          background-color: mat.get-color-from-palette($accent, 500);
          > .circle {
            background-color: mat.get-color-from-palette($greyscale, 50);
          }
        }
        &.deselected {
          background-color: mat.get-color-from-palette($greyscale, 700);
          > .circle {
            background-color: mat.get-color-from-palette($greyscale, 200);
          }
        }
      }
    }
  }
}
