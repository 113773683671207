@use "@angular/material" as mat;
@use "sass:map";
@use "../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .graphTooltip {
    @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $accent: map.get($config, "accent");
  $foreground: map.get($config, "foreground");
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");
  $transparent: map.get($config, "transparency");

  .canvasjs-chart-tooltip,
  .graphPopup {
    > .area-dialog {
      min-width: 280px !important;
      height: 114px !important;
      .buttons {
        border-top: 1px solid mat.get-color-from-palette($greyscale, 700);
      }
    }
    > div {
      background-color: mat.get-color-from-palette($greyscale, 850) !important;
      border-radius: 2px !important;
      @include nista.box-border(mat.get-color-from-palette($transparent, 40));
      border: none !important;

      .graphTooltip {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        padding: 2px 4px;
        color: mat.get-color-from-palette($greyscale, 100);

        .datetime {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 16px;
          width: 100%;
          padding-bottom: 2px;
        }
        .lower {
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;
          .dot {
            border-radius: 100%;
            width: 6px;
            height: 6px;
          }
        }

        .tooltip-values {
          display: flex;
          width: 100%;
          gap: 8px;
          align-items: center;
          .date {
            margin-right: auto;
            display: flex;
            gap: 8px;
            align-items: center;
            color: mat.get-color-from-palette($greyscale, 100);
          }
          .dot {
            border-radius: 100%;
            width: 6px;
            height: 6px;
          }
          .value {
            margin-left: 16px;
            color: mat.get-color-from-palette($greyscale, 100);
          }

          .line {
            width: 15px;
            display: inline-block;
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px;
        gap: 4px;

        width: 100%;
        height: 40px;

        .button {
          width: 132px;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .line {
          /* Greyscale/700 */
          border: 1px solid mat.get-color-from-palette($greyscale, 700);
        }
      }
    }
    .comment-dialog {
      display: flex;
      height: 164px !important;
      width: 280px !important;
      flex-direction: column;
      .comment-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          padding-left: 8px;
        }
      }
      .comment-body {
        padding: 8px;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 8px 8px;
      }
    }
  }
}
