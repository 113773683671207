@use "@angular/material" as mat;
@use "sass:map";
@use "../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-status {
    .loc-table {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Mono");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  $transparent: map.get($config, "transparency");

  app-status {
    .banner {
      background-color: mat.get-color-from-palette($greyscale, 1000);
      border-bottom-color: mat.get-color-from-palette($greyscale, 800);
    }
    .status {
      tr.yellow {
        background-color: rgba(255, 255, 0, 0.25);
      }
      tr.red {
        background-color: rgba(255, 0, 0, 0.25);
      }
    }

    .loc-table {
      thead td {
        background-color: mat.get-color-from-palette($greyscale, 600);
        color: mat.get-color-from-palette($greyscale, A100);
        font-weight: bold;
        font-size: 13px;
        border-color: mat.get-color-from-palette($greyscale, 600);
      }

      tbody td {
        color: mat.get-color-from-palette($greyscale, 600);
        border-color: mat.get-color-from-palette($greyscale, 200);
      }

      tbody tr {
        background-color: mat.get-color-from-palette($greyscale, 150);
      }

      tbody tr:nth-child(odd) {
        background-color: mat.get-color-from-palette($greyscale, A100);
      }
    }

    .workspace-card {
      @include nista.box-border(mat.get-color-from-palette($transparent, 40));
    }
  }
}
