@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");

  .dropdown-date {
    background-color: rgba(255, 255, 255, 0.04);
    color: mat.get-color-from-palette($greyscale, A100);
    z-index: 1001;

    &.opened {
      border-bottom: 1px solid mat.get-color-from-palette($greyscale, A100);
      border-right: 1px solid mat.get-color-from-palette($greyscale, A100);
      border-left: 1px solid mat.get-color-from-palette($greyscale, A100);
      border-top: 1px solid mat.get-color-from-palette($greyscale, A100);
      background-color: mat.get-color-from-palette($greyscale, 850);
    }
  }
}
