@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $greyscale: map.get($config, "greyscale");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $transparency: map.get($config, "transparency");

  .settings-home-root {
    color: mat.get-color-from-palette($greyscale, 100);

    h2, h3 {
      color: mat.get-color-from-palette($greyscale, 200);
    }

    .section {
      background-color: mat.get-color-from-palette($background, bg-container-2);
      border: 1px solid mat.get-color-from-palette($greyscale, 800);
      border-radius: 2px;
    }

    .section-separation-top {
      @include nista.box-border-top(
        mat.get-color-from-palette($greyscale, 800)
      );
    }

    .section-separation-bottom {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 800)
      );
    }

    .section-separation-bottom:last-of-type {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($transparency, 5)
      );
    }

    .section-header {
      p {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }

    .workspace-page-root {
      h2 {
        color: mat.get-color-from-palette($greyscale, 200);
      }

      h3 {
        color: mat.get-color-from-palette($greyscale, 200);
      }

      .workspace-detail {
        .title {
          color: mat.get-color-from-palette($greyscale, 300);
        }
        .name {
          color: mat.get-color-from-palette($greyscale, 150);
        }
      }

      .workspace-icon {
        background-color: #02c6d1;
      }
    }

    .profile-page-root {
      .info-container {
        .name {
          color: mat.get-color-from-palette($greyscale, 150);
        }
        .contact {
          color: mat.get-color-from-palette($greyscale, 300);
        }
      }

      .password {
        b {
          color: mat.get-color-from-palette($greyscale, 200);
        }
        p {
          color: mat.get-color-from-palette($greyscale, 300);
        }
      }
    }

    .notification-settings-root {
      color: mat.get-color-from-palette($greyscale, 200);
      p {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }

    .security-page-root {
      color: mat.get-color-from-palette($greyscale, 200);
      .section-description {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }
  }

  .settings-home-root-dialog {
    &.invite-user-dialog {
      h4 {
        color: mat.get-color-from-palette($greyscale, 50);
      }
    }

    &.profile-page-password-dialog,
    &.cancel-edit-dialog,
    &.security-page-2fa-dialog,
    &.security-page-PAT-dialog {
      color: mat.get-color-from-palette($greyscale, 200);
      h3,
      h4 {
        color: mat.get-color-from-palette($greyscale, 50);
      }
    }
  }
}

@mixin typography($typography) {
  .settings-home-root {
    h2 {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }

    h3 {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }

    .section-header {
      p {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
    }
    
    .workspace-page-root {
      .workspace-detail {
        .title {
          @include mat.typography-level(
            $typography,
            "Desktop/Oxygen/md/Regular"
          );
        }
        .name {
          @include mat.typography-level($typography, "Desktop/Oxygen/xxl/Bold");
        }
      }
    }

    .profile-page-root {
      .info-container {
        .name {
          @include mat.typography-level($typography, "Desktop/Oxygen/xxl/Bold");
        }
        .contact {
          @include mat.typography-level(
            $typography,
            "Desktop/Oxygen/xl/Regular"
          );
        }
      }

      .password {
        b {
          @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
        }
        p {
          @include mat.typography-level(
            $typography,
            "Desktop/Oxygen/xl/Regular"
          );
        }
      }
    }

    notification-settings-root {
      p {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
    }

    .security-page-root {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");

      .grant-box {
        b {
          @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
        }
        div {
          @include mat.typography-level(
            $typography,
            "Desktop/Oxygen/md/Regular"
          );
        }
      }
    }
  }

  .settings-home-root-dialog {
    &.invite-user-dialog {
      h4 {
        @include mat.typography-level($typography, "Aeroport/xxl/medium");
      }
    }

    &.profile-page-password-dialog {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }

    &.cancel-edit-dialog {
      h4 {
        @include mat.typography-level($typography, "Aeroport/xxl/medium");
      }
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }

    &.security-page-2fa-dialog,
    &.security-page-PAT-dialog {
      h4 {
        @include mat.typography-level($typography, "Aeroport/xxl/medium");
      }
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}
