@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $error: map.get($config, "error");

  app-weekly-statistics, app-monthly-statistics, app-quarterly-statistics, app-yearly-statistics {
    .upper-graphs {
      width: 100%;
      height: 280px;
      display: flex;
      gap: 10px;

      .barChart {
        flex-grow: 1;
      }

      app-aggregation-comparison {
        width: 328px;
      }
    }

    .widget {
      width: 100%;
      height: 202px;
      display: flex;
      gap: 10px;

      &.darkChart {
        flex-direction: row;
      }

      .chart {
        flex-grow: 1;
      }
    }

    .darkChart {
      padding: 16px;
      display: flex;
      flex-direction: column;

      app-canvasjs-comparison-chart, app-canvasjs-bar-chart {
        flex-grow: 1;
      }
    }

    .statistics {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .dot {
      border-radius: 100%;
      width: 8px;
      height: 8px;
    }

    .legend {
      padding-left: 24px;
      display: flex;
      gap: 24px;
      margin: 10px 0px;
    }

    .legend-entry {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .line-graph {
      height: 255px;
    }

    .chart-title {
      display: flex;
      align-items: center;
    }

  }
}
