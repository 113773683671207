@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
    $color: mat.get-color-config($theme);
    @if $color != null {
        @include color($color);
    }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");

    ::ng-deep .mat-dialog-container {
      background-color: mat.get-color-from-palette($background, "dark-bg-lighter-10") !important;
    }
    .header-button {
      app-gnista-icon{
        color: mat.get-color-from-palette($background, "dark-bg-lighter-30");
      }
      app-gnista-icon:hover{
        color: white;
      }
    }

    .share-dialog-container {
      background-color: mat.get-color-from-palette($background, "dark-bg-lighter-10");
    }
    .simple-share > p {
      scrollbar-color: mat.get-color-from-palette($background, "dark-bg-lighter-20") mat.get-color-from-palette($background, "dark-bg-lighter-10");
    }
    .share-link {
      border-color: mat.get-color-from-palette($background, "dark-bg-lighter-20");
    }
    .share-link:hover{
      border-color: white;
    }
    .permission-content {
      button {
        border-color: mat.get-color-from-palette($background, "dark-bg-lighter-20");
      }
    }
    .share-infobox {
      background-color: mat.get-color-from-palette($background, "dark-bg-lighter-20");
    }
    .separator {
      border-bottom-color: mat.get-color-from-palette($background, "dark-bg-lighter-5");
    }
    .mat-form-field.mat-focused.mat-primary .mat-select-arrow{
      color: white !important;
    }
    .mat-option{
      color: white !important;
    }
    .mat-option.mat-active{
      color: mat.get-color-from-palette($background, "dark-bg-lighter-30") !important;
    }
}
