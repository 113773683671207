@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .violation-list-root {
    .page-header {
      h1 {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/xxl/Regular"
        );
      }
    }

    .violation-dscription {
      @include mat.typography-level($typography, "Desktop/Oxygen/xxl/Regular");
    }

    .header {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }

    .basic-info {
      .category {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
      }
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }

    h4 {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }
    p {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }

    .widget {
      h3 {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
      span {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/xl/Regular"
        );
      }
      &.energyCostError {
        span {
          @include mat.typography-level(
            $typography,
            "Desktop/Aeroport/lg/Regular"
          );
        }
      }
    }

    .emptyList {
      span,
      p {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
      }
      h4 {
        @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $transparent: map.get($config, "transparency");
  $warning: map.get($config, "warn");

  .violation-list-root {
    .violation-dscription {
      color: mat.get-color-from-palette($greyscale, 200);
    }

    .violation-gallery {
      border-radius: 2px;
      border: 1px solid mat.get-color-from-palette($transparent, 40);
      background-color: mat.get-color-from-palette($greyscale, 1000);

      .header {
        @include nista.box-border-bottom(
          mat.get-color-from-palette($transparent, 40)
        );
        span {
          color: mat.get-color-from-palette($greyscale, 200);
        }
      }

      .basic-info {
        .category {
          color: mat.get-color-from-palette($greyscale, 300);
        }
        color: mat.get-color-from-palette($greyscale, 200);
        .starDate,
        .time {
          @include nista.icon-color(
            mat.get-color-from-palette($greyscale, 200)
          );
        }
      }

      h4 {
        color: mat.get-color-from-palette($greyscale, 150);
      }
      p {
        color: mat.get-color-from-palette($greyscale, 200);
      }

      .consumption-graph {
        .graph {
          background-color: mat.get-color-from-palette($greyscale, 900);
        }
        .label {
          background-color: mat.get-color-from-palette($greyscale, 900);
        }
      }

      .widget {
        border-radius: 2px;
        background-color: mat.get-color-from-palette($greyscale, 900);

        h3 {
          color: mat.get-color-from-palette($greyscale, 100);
        }
        span {
          color: mat.get-color-from-palette($greyscale, 100);
        }

        &.energyCostError {
          @include nista.icon-color(mat.get-color-from-palette($warning, 500));
        }
      }
    }

    .emptyList {
      h4 {
        color: mat.get-color-from-palette($greyscale, 150);
      }
      span,
      p {
        color: mat.get-color-from-palette($greyscale, 200);
      }
    }
  }
}
