@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
    $color: mat.get-color-config($theme);
    @if $color != null {
        @include color($color);
    }
}

@mixin color($config-or-theme) {
    $config: mat.get-color-config($config-or-theme);
    $primary: map.get($config, primary);
    $greyscale: map.get($config, "greyscale");

    .placeholder-inline {
        color: mat.get-color-from-palette($greyscale, 700) !important;
        opacity: 1;
        cursor: pointer;
    }

    .editField > input {
      color: mat.get-color-from-palette($greyscale, A100);
      background-color: rgba(0, 0, 0, 0);
  }
}
