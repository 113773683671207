@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-confirm-dialog-display {
    .dialog-title {
      @include mat.typography-level($typography, "Aeroport/xxl/medium");
    }

    .dialog-subtitle {
      @include mat.typography-level($typography, "Desktop/Aeroport/sm/Regular");
    }

    .dialog-text {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");

  app-confirm-dialog-display {
    background-color: mat.get-color-from-palette($greyscale, 850);

    .dialog-title {
      color: mat.get-color-from-palette($greyscale, 50);
    }

    .dialog-subtitle {
      color: mat.get-color-from-palette($greyscale, 150);
    }

    .dialog-text {
      color: mat.get-color-from-palette($greyscale, 200);
    }
  }
}
