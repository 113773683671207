@use '@angular/material'as mat;
@use 'sass:map';

@mixin theme($theme) {
    $color: mat.get-color-config($theme);

    @if $color !=null {
    @include color($color);
    }
}

@mixin color($config-or-theme) {
    $config: mat.get-color-config($config-or-theme);
    $primary: map.get($config, primary);
    $accent: map.get($config, accent);
    $foreground: map.get($config, foreground);
    $background: map.get($config, "background");
    $greyscale: map.get($config, "greyscale");

    .unitselector > label{
        color: mat.get-color-from-palette($greyscale, 100)
    }
}