@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .page-header-container {
    &.page-header-xlarge {
      .header {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/xxl/Regular"
        );
      }
    }
    &.page-header-large {
      .header,
      .header h1 {
        @include mat.typography-level(
          $typography,
          "Desktop/Aeroport/lg/Regular"
        );
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");

  .page-header-container {
    .header {
      h1 {
        color: mat.get-color-from-palette($greyscale, A100);
      }
      &.editable:hover {
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
    }
  }
}
