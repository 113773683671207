@use "@angular/material"as mat;
@use "sass:map";
@use '../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-workspace-wizard {
    .heading {
      @include mat.typography-level($typography, "Desktop/Aeroport/xl/Regular");
    }

    .sub-heading {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  app-workspace-wizard {
    .heading {
      color: mat.get-color-from-palette($greyscale, A100);
    }

    .sub-heading {
      color: mat.get-color-from-palette($greyscale, 300);
    }

    .content {
      background-color: mat.get-color-from-palette($greyscale, 850);
    }

    .banner {
      color: mat.get-color-from-palette($greyscale, 300);
      background-color: mat.get-color-from-palette($greyscale, 1000);
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 800)
      );
    }
  }
}
