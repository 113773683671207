@use "@angular/material" as mat;
@use "sass:map";
@use "../nista-mixins" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .upload-container {
    .text-block {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
  .nista-file-box {
    .nista-file-box-text {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
    }
  }
  .data-library-root {
    @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    .data-library-header {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }
  }
  .file-details-root {
    .settings-section {
      p {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
      }
    }
    .import-card-data-row {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Mono");
    }
    .import-card-data-name {
      @include mat.typography-level($typography, "Desktop/Aeroport/lg/Regular");
    }
    .import-card-date {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }
  }
}

@mixin color($theme) {
  // Get the color config from the theme.
  $config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary: map.get($config, "primary");
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");
  $error: map.get($config, "error");
  $accent: map.get($config, "accent");

  .nista-file-box {
    box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 700);
    background-color: mat.get-color-from-palette($greyscale, 850);
    .nista-file-box-text {
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 150));
      fill: mat.get-color-from-palette($greyscale, 150);
      color: mat.get-color-from-palette($greyscale, 150);
    }

    .nista-file-box-icon {
      @include nista.icon-color(mat.get-color-from-palette($greyscale, A100));
      fill: mat.get-color-from-palette($greyscale, A100);
      color: mat.get-color-from-palette($greyscale, A100);
    }
  }

  .drop-zone,
  .drop-zone-area {
    border-color: mat.get-color-from-palette($greyscale, 400);
    background-color: mat.get-color-from-palette($greyscale, 850);
  }

  .drop-zone-dotts {
    // Accent 600 but with Opacity 0.5
    background-color: rgba(32, 76, 196, 0.5);
    border-color: mat.get-color-from-palette($accent, 500);
  }

  .data-library-root {
    color: mat.get-color-from-palette($greyscale, 100);
    .data-library-header {
      color: mat.get-color-from-palette($greyscale, 300);
    }

    .alternating {
      &:nth-of-type(odd) {
        background-color: mat.get-color-from-palette($greyscale, 850);
      }
    }

    .data-library-header {
      background-color: mat.get-color-from-palette($greyscale, 850);
    }

    .link {
      @include nista.icon-color(mat.get-color-from-palette($greyscale, 100));
      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
    }
  }

  .file-details-root {
    .import-container {
      @include nista.box-border(
        mat.get-color-from-palette($greyscale, 700)
      );

      .import-container-header {
        @include nista.box-border-bottom(
          mat.get-color-from-palette($greyscale, 700)
        );
      }
    }

    .settings-section {
      p {
        color: mat.get-color-from-palette($greyscale, 200);
      }
    }

    .import-card {
      background-color: mat.get-color-from-palette($greyscale, 1000);
      @include nista.box-border(mat.get-color-from-palette($greyscale, 700));
      &-data-row {
        @include nista.box-border-top(
          mat.get-color-from-palette($greyscale, 700)
        );
      }
      .import-card-data-name {
        color: mat.get-color-from-palette($greyscale, 100);
      }
      .date {
        color: mat.get-color-from-palette($greyscale, 200);
      }
      .value {
        color: mat.get-color-from-palette($greyscale, 100);
      }
      .import-details {
        color: mat.get-color-from-palette($greyscale, 100);
      }
    }
  }
}
