@mixin icon-color($color) {
  app-gnista-icon {
    .icon {
      background-color: $color !important;
    }
  }
  .icon {
    background-color: $color !important;
  }
}

@mixin box-border($color, $size: "1px") {
  box-shadow: 0 0 0 unquote($size) inset $color;
  -webkit-box-shadow: 0 0 0 unquote($size) inset $color;
  -moz-box-shadow: 0 0 0 unquote($size) inset $color;
}

@mixin box-border-top($color, $size: "1px") {
  box-shadow: 0px unquote($size) 0px 0px $color inset;
  -webkit-box-shadow: 0px unquote($size) 0px 0px $color inset;
  -moz-box-shadow: 0px unquote($size) 0px 0px $color inset;
}

@mixin box-border-bottom($color, $size: "1px") {
  box-shadow: calc(-1 * unquote($size)) calc(-2 * unquote($size)) 0px
    calc(-1 * unquote($size)) $color inset;
  -webkit-box-shadow: calc(-1 * unquote($size)) calc(-2 * unquote($size)) 0px
    calc(-1 * unquote($size)) $color inset;
  -moz-box-shadow: calc(-1 * unquote($size)) calc(-2 * unquote($size)) 0px
    calc(-1 * unquote($size)) $color inset;
}

@mixin box-border-right($color, $size: "1px") {
  box-shadow: calc(-1 * unquote($size)) 0px 0px 0px $color inset;
  -webkit-box-shadow: calc(-1 * unquote($size)) 0px 0px 0px $color inset;
  -moz-box-shadow: calc(-1 * unquote($size)) 0px 0px 0px $color inset;
}

@mixin box-border-left($color, $size: "1px") {
  box-shadow: unquote($size) 0px 0px 0px $color inset;
  -webkit-box-shadow: unquote($size) 0px 0px 0px $color inset;
  -moz-box-shadow: unquote($size) 0px 0px 0px $color inset;
}
