@use "@angular/material" as mat;
@use "sass:map";
@use "../../../../../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }


  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-nista-table {
    .number {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Mono");
    }
    .legend-entry {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
}


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $error: map.get($config, "error");

  app-weekly-consumption-enpi-statistics, app-monthly-consumption-enpi-statistics, app-quarterly-consumption-enpi-statistics, app-yearly-consumption-enpi-statistics,
  app-weekly-efficiency-enpi-statistics, app-monthly-efficiency-enpi-statistics, app-quarterly-efficiency-enpi-statistics, app-yearly-efficiency-enpi-statistics {
    .upper-graphs {
      width: 100%;
      height: 220px;
      display: flex;
      gap: 10px;

      app-nista-table {
        flex-grow: 1;
      }

      app-nista-comparison-plus-linechart {
        flex-grow: 1;
      }
    }

    .barChart {
      width: 100%;
      height: 280px;
    }

    .darkChart {
      padding: 16px;
      display: flex;
      flex-direction: column;

      app-canvasjs-bar-chart {
        flex-grow: 1;
      }
    }

    .statistics {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .dot {
      border-radius: 100%;
      width: 8px;
      height: 8px;
    }

    .legend {
      padding-left: 24px;
      display: flex;
      gap: 24px;
      margin: 10px 0px;
    }

    .legend-entry {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .chart-title {
      display: flex;
      align-items: center;
    }
  }
}
