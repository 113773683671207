@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  app-nista-trend-icon {
    .trend {
      &.worsened {
        color: #ff4858;
        &:not(.circleColorOverride) i {
          background-color: #ff48584d !important;
        }
        &:not(.arrowColorOverride) .trend-icon-inner {
          i {
            background-color: #ff4858 !important;
          }
        }
      }
      &.improved {
        &:not(.textColorOverride){
          color: mat.get-color-from-palette($success, 300);
        }
        
        &:not(.circleColorOverride) i {
          background-color: rgba(
            mat.get-color-from-palette($success, 300),
            0.3
          ) !important;
        }
        &:not(.arrowColorOverride) .trend-icon-inner {
          i {
            background-color: mat.get-color-from-palette(
              $success,
              300
            ) !important;
          }
        }
      }
      &.downtrend {
        i {
          transform: rotate(90deg);
        }
        .trend-icon-inner {
          i {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
