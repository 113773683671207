@use "@angular/material"as mat;
@use "sass:map";
@use '../../../nista-mixins.scss' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .nista-alert-root {
    .headline {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }
    .details {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
    .actions {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $greyscale: map.get($config, "greyscale");
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  $info: map.get($config, "info");
  $warn: map.get($config, "warn");

  .nista-alert-root {
    &.error {
      @include nista.box-border(mat.get-color-from-palette($error, 800), "1px");
      background-color: mat.get-color-from-palette($error, 950);
      @include nista.icon-color(mat.get-color-from-palette($error, 500));
      .headline {
        color: mat.get-color-from-palette($error, 500);
      }
      color: mat.get-color-from-palette($error, 100);
    }
    &.info {
      @include nista.box-border(mat.get-color-from-palette($info, 800), "1px");
      background-color: mat.get-color-from-palette($info, 950);
      @include nista.icon-color(mat.get-color-from-palette($info, 500));
      .headline {
        color: mat.get-color-from-palette($info, 500);
      }
      color: mat.get-color-from-palette($info, 100);
    }
    &.warn {
      @include nista.box-border(mat.get-color-from-palette($warn, 800), "1px");
      background-color: mat.get-color-from-palette($warn, 950);
      @include nista.icon-color(mat.get-color-from-palette($warn, 500));
      .headline {
        color: mat.get-color-from-palette($warn, 500);
      }
      color: mat.get-color-from-palette($warn, 100);
    }
    &.success {
      @include nista.box-border(
        mat.get-color-from-palette($success, 800),
        "1px"
      );
      background-color: mat.get-color-from-palette($success, 950);
      @include nista.icon-color(mat.get-color-from-palette($success, 500));
      .headline {
        color: mat.get-color-from-palette($success, 500);
      }
      color: mat.get-color-from-palette($success, 100);
    }
  }
}
