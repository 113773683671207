@use "@angular/material" as mat;
@use "sass:map";
@use "../../../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  /* Small devices (landscape phones, 576px and up) */
  @media (max-width: 768px) {
    .nista-table-root {
      .header-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/xs/Bold");
      }
      .table-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/xs/Regular");
        > .table-cell.table-cell-mono {
          @include mat.typography-level($typography, "Desktop/Oxygen/xs/Mono");
        }
      }
    }
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {
    .nista-table-root {
      .header-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
      }
      .table-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
        > .table-cell.table-cell-mono {
          @include mat.typography-level($typography, "Desktop/Oxygen/sm/Mono");
        }
      }
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .nista-table-root {
      .header-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
      }
      .table-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
        > .table-cell.table-cell-mono {
          @include mat.typography-level($typography, "Desktop/Oxygen/sm/Mono");
        }
      }
    }
  }

  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {
    .nista-table-root {
      .header-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
      }
      .table-row {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
        > .table-cell.table-cell-mono {
          @include mat.typography-level($typography, "Desktop/Oxygen/md/Mono");
        }
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");
  $warn: map.get($config, "warn");
  $transparency: map.get($config, "transparency");

  app-nista-expander {
    .nista-table-root {
      box-shadow: unset;
      -webkit-box-shadow: unset;
      -moz-box-shadow: unset;
      .table-row {
        color: mat.get-color-from-palette($greyscale, 200);
        background-color: #171717;

        &:last-of-type {
          box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.16) inset;
          -webkit-box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.16) inset;
          -moz-box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.16) inset;
        }

        &,
        &.selectable {
          &:hover {
            background-color: mat.get-color-from-palette($transparency, 10);
          }
        }
      }
    }
  }

  .nista-table-root {
    @include nista.box-border(mat.get-color-from-palette($transparency, 40));

    .table-row {
      &.selectable {
        &:hover {
          background-color: mat.get-color-from-palette($transparency, 30);
        }
        &.selected {
          background-color: mat.get-color-from-palette($uiprimary, 700);
          color: mat.get-color-from-palette($greyscale, 200);
          &:hover {
            background-color: mat.get-color-from-palette($uiprimary, 850);
          }
        }
      }
    }

    .header-row {
      color: mat.get-color-from-palette($greyscale, 300);
      background-color: mat.get-color-from-palette($transparency, 30);
    }

    .table-row {
      color: mat.get-color-from-palette($greyscale, 200);
      background-color: mat.get-color-from-palette($transparency, 0);
      &.disabled {
        color: mat.get-color-from-palette($greyscale, 300);
      }
      &.warning {
        background-color: mat.get-color-from-palette($warn, 950);
        color: mat.get-color-from-palette($warn, 200);
        &:hover {
          background-color: mat.get-color-from-palette($warn, 850);
        }
      }

      &:not(:last-of-type) {
        @include nista.box-border-bottom(
          mat.get-color-from-palette($transparency, 40)
        );
      }
    }
  }
}
